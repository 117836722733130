import {LitElement, html, css} from 'lit';
import {sharedStyles} from '../shared-styles';
/**
 * `home-page`
 *  The first page the user sees
 */
export class HomePage extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    const style = css`
      .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        list-style: none;
        gap: 16px;
      }

      .item a {
        display: flex;
        background: var(--black);
        color: var(--white);
        justify-content: center;
        align-items: center;
        height: 150px;
        gap: 8px;
        text-decoration: none;
      }

      .item a:hover {
        background: #333;
      }
    `;
    return [sharedStyles, style];
  }

  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    return html`
      <ul class="grid">
        <li class="item">
          <a href="/price-updates">
            <span class="material-icons">paid</span>
            Update Prices
          </a>
        </li>
        <li class="item">
          <a href="/price-discounts" class="item">
            <span class="material-icons">sell</span>
            Price Discounts
          </a>
        </li>
        <li class="item">
          <a href="/schedule-management">
            <span class="material-icons">edit_calendar</span>
            Manage Schedules
          </a>
        </li>
        <li class="item">
          <a href="/intake-rrp-conversion">
            <span class="material-icons">shopping_cart_checkout</span>
            Intake RRP
          </a>
        </li>
      </ul>
    `;
  }
}
window.customElements.define('home-page', HomePage);
