/* eslint-disable max-len */
/* eslint-disable lit/binding-positions */
/* eslint-disable object-curly-spacing */
/* eslint-disable indent */
/* eslint-disable comma-dangle */

import { LitElement, html, css, nothing } from 'lit';
import '@material/mwc-button';
import { status } from '../elements/statuses';
import { sharedStyles } from '../shared-styles';
import { datadogRum } from '@datadog/browser-rum';
import '../elements/upload-item';
import '@material/web/tabs/primary-tab';
import '@material/web/tabs/secondary-tab';
import '@material/web/tabs/tabs';
import '@material/web/progress/linear-progress';

/**
 * `price-discount-page`
 *  Display the uploader for product price discounts files
 */
export class PriceDiscountPage extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    const style = css`
      small {
        display: block;
        margin: 10px;
      }

      small > a {
        display: block;
      }

      input[type='datetime-local'] {
        width: 25%;
      }

      /*Some elements need to be disabled for the TPP work to go live*/
      .temp-hide {
        display: none;
      }

      .tabs {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        background-color: #eeeeee;
        border-radius: 24px;
        margin-bottom: 24px;
      }

      .tab {
        background-color: #eeeeee;
        padding: 16px;
        border: none;
        font-size: 16px;

        &:first-of-type {
          border-radius: 24px 0 0 24px;
        }

        &:last-of-type {
          border-radius: 0 24px 24px 0;
        }

        &[active] {
          background-color: black;
          color: white;
          border-radius: 24px;
          font-weight: 600;
        }
      }

      .panel {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 90%;
      }

      .panel[hidden] {
        display: none;
      }

      .button-group {
        display: flex;
        align-items: flex-end;
        flex-direction: row;
        justify-content: end;
        gap: 8px;
      }

      .file-upload-wrapper {
        position: relative;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23919191' stroke-width='6' stroke-dasharray='10%2c25' stroke-dashoffset='36' stroke-linecap='square'/%3e%3c/svg%3e");
        height: 300px;
        border-radius: 24px;
        border: none;
        margin-top: 16px;
        width: 100%;
        box-sizing: border-box;
        cursor: pointer;

        .material-icons {
          position: absolute;
          left: 50%;
          top: 50%;
          font-size: 70px;
          transform: translate(-50%, -50%);
        }

        &:hover {
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23000' stroke-width='6' stroke-dasharray='10%2c25' stroke-dashoffset='36' stroke-linecap='square'/%3e%3c/svg%3e");

          .material-icons {
            font-size: 74px;
          }
        }
      }

      .file-upload {
        opacity: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;

        &::file-selector-button {
          display: none;
        }

        &:valid {
          opacity: 1;
          border: none;
          padding: 100px 38%;
          box-sizing: border-box;
          color: transparent;
          background: #f7f7f7;
        }
      }

      .error {
        background: #eeeeee;
        padding: 1.6rem;
        border-radius: 0.8rem;
        font-family: monospace;
      }

      .filename {
        position: absolute;
        top: 65%;
        left: 0;
        right: 0;
        transform: translateY(-35%);
        text-align: center;
      }

      .loading {
        text-align: center;
        font-size: 2.4rem;
        font-weight: 700;
        font-family: 'montserrat';
        text-transform: uppercase;
      }

      section {
        margin-top: 2.4rem;
        padding-top: 1.2rem;
        border-top: 1px solid #ccc;
        max-width: 1248px;
      }

      .results {
        max-height: 100%;
        overflow-y: auto;
      }
    `;
    return [sharedStyles, style];
  }

  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    return html`
      <h2 class="page-heading">Price Discount Uploader</h2>
      <div class="page-body">
        <div class="tabs" role="tablist" aria-label="Content to view">
          <button
            class="tab"
            role="tab"
            id="info-tab"
            aria-controls="info-panel"
            @click="${() => this.setActiveTabIndex(1)}"
            active=${this.activeTabIndex === 1 ? true : nothing}
          >
            Info
          </button>
          <button
            class="tab"
            role="tab"
            id="upload-tab"
            aria-controls="upload-panel"
            @click="${() => this.setActiveTabIndex(2)}"
            active=${this.activeTabIndex === 2 ? true : nothing}
            ?disabled=${this.activeTabIndex === 4}
          >
            Upload
          </button>

          <button
            class="tab"
            role="tab"
            id="validations-tab"
            aria-controls="validations-panel"
            @click="${() => this.setActiveTabIndex(3)}"
            active=${this.activeTabIndex === 3 ? true : nothing}
            ?disabled=${this.activeTabIndex !== 3}
          >
            Validate
          </button>
          <button
            class="tab"
            role="tab"
            id="confirm-tab"
            aria-controls="confirm-panel"
            @click="${() => this.setActiveTabIndex(4)}"
            active=${this.activeTabIndex === 4 ? true : nothing}
            ?disabled=${this.activeTabIndex < 4}
          >
            Confirm
          </button>
        </div>
        <div
          class="panel"
          id="info-panel"
          role="tabpanel"
          aria-labelledby="info-tab"
          hidden=${this.activeTabIndex !== 1 ? true : nothing}
        >
          <div>
            <small
              >The CSV file is expected in a specific format.
              <a 
                class="temp-hide"
                href="/templates/international-price-discounts.csv"
                download="price-discounts.csv"
                >Download the International template here.</a
              >
              <a
                class="temp-hide"
                href="/templates/north-america-price-discounts.csv"
                download="price-discounts.csv"
                >Download the North America template here.</a
              >
              <a href="/templates/retail-price-discounts.csv" download="price-discounts.csv">
                Download the template for retail store price updates
              </a>
              <ul>
                <li>
                  If you do not wish to discount a store, then make sure to
                  remove the entire column representing the store
                </li>
                <li>You need as a minimum one UNCLE SKU to update all linked variants</li>
                <li>Values must not include any currency or percentage symbols</li>
                <li>The value is the discounted price of product i.e. the target price after markdown</li>
                <li>
                  A successful upload will simply disappear – you will not receive a confirmation,
                  but unless the UI displays an error, you upload is successful.
                </li>
              </ul>
            </small>
          </div>

          <div class="button-group">
            <button
              class="button submit"
              @click=${() => this.setActiveTabIndex(2)}
            >
              Continue
            </button>
          </div>
        </div>
        <div
          class="panel"
          id="upload-panel"
          role="tabpanel"
          aria-labelledby="upload-tab"
          hidden=${this.activeTabIndex !== 2 ? true : nothing}
        >
          <div>
            <form ?hidden=${this.state == status.Saving}>
              <label class="temp-hide">
                Would you like to schedule when these discounts go live?
                (optional)
                <input
                  name="scheduledTime"
                  type="datetime-local"
                  min=${PriceDiscountPage.minScheduledTime}
                />
              </label>
              <label>
                Upload a Price Discount CSV file
                <div class="file-upload-wrapper">
                  <input
                    class="file-upload"
                    name="upload"
                    type="file"
                    required
                    accept=".csv"
                    @drop=${this.onDrop}
                    @change=${this.onChange}
                  />
                  <span class="material-icons" 
                        id="upload-icon"
                        @drop="${this.onDropPass}"
                        @dragover="${PriceDiscountPage.onDragOverPass}"
                  >upload</span>
                  <span class="filename" 
                        id="file-upload-visualiser"
                        @drop="${this.onDropPass}"
                        @dragover="${PriceDiscountPage.onDragOverPass}"
                  ></span>
                </div>
              </label>
            </form>
            <p
              aria-live="assertive"
              class="error"
              ?hidden=${this.state !== status.Errored}
            >
              Oops there has been an error uploading. Please try again.
              ${this.error?.error || this.error}
            </p>
          </div>
          <div class="loading" ?hidden=${this.state != status.Saving}>
            <span>Validating...</span>
            <md-linear-progress indeterminate></md-linear-progress>
          </div>
          <div class="button-group">
            <button class="button cancel secondary" @click=${this.resetForm}>
              Clear
            </button>
            <button
              class="button submit"
              @click=${this.validateUpload}
              ?disabled=${this.state == status.Saving}
            >
              <span ?hidden=${this.state == status.Saving}>Upload</span>
              <div class="button-icon" ?hidden=${this.state !== status.Saving}>
                <span class="material-icons">sync</span>
              </div>
            </button>
          </div>
        </div>
        <div
          class="panel"
          id="validations-panel"
          role="tabpanel"
          aria-labelledby="validations-tab"
          hidden=${this.activeTabIndex !== 3 ? true : nothing}
        >
          <div class="results">
            <div
              ?hidden=${!this.validationResponse?.discounts ||
              this.state == status.Saving}
            >
              <h3>
                ⚠️ You are about to change
                ${this.validationResponse?.discounts &&
                Object.values(this.validationResponse?.discounts).reduce(
                  (a, b) => {
                    return a + b.totalSkus;
                  },
                  0
                )}
                skus changed across
                ${this.validationResponse?.discounts &&
                Object.values(this.validationResponse?.discounts)?.length}
                stores
              </h3>
              <p>
                If you are happy with these proposed changes please click the
                "Confirm" button at the bottom right of this panel.
              </p>
              ${this.validationResponse?.discounts &&
              Object.values(this.validationResponse?.discounts).map(
                (p) =>
                  html`<ul>
                    <li>
                      ${p.totalSkus} skus changed for: ${p.storeName}
                      <ul>
                        ${Object.entries(p.storeDiscounts).map(
                          ([percent, total]) =>
                            html`<li>
                              <strong>${total}</strong> SKUs discounted to
                              <strong>${percent}</strong>
                            </li>`
                        )}
                      </ul>
                    </li>
                  </ul>`
              )}
            </div>
            <p
              class="error"
              aria-live="assertive"
              ?hidden=${this.state !== status.Errored}
            >
              There has been an error getting the uploads.
              ${this.error?.error || this.error}
            </p>
          </div>
          <div class="loading" ?hidden=${this.state != status.Saving}>
            <span>Processing...</span>
            <md-linear-progress indeterminate></md-linear-progress>
          </div>
          <div class="button-group">
            <button
              class="button"
              ?hidden=${!this.validationResponse?.discounts}
              @click=${this.cancelValidation}
            >
              Cancel
            </button>
            <button
              class="button submit"
              @click=${this.confirmUpload}
              ?disabled=${this.state == status.Saving}
            >
              <span ?hidden=${this.state == status.Saving}>Confirm</span>
              <div class="button-icon" ?hidden=${this.state !== status.Saving}>
                <span class="material-icons">sync</span>
              </div>
            </button>
          </div>
        </div>
        <div
          class="panel"
          id="confirm-panel"
          role="tabpanel"
          aria-labelledby="confirm-tab"
          hidden=${this.activeTabIndex !== 4 ? true : nothing}
        >
          <div class="results" ?hidden=${!this.confirmedResponse?.discounts}>
            <h3>
              ✅ You have successfully changed
              ${this.confirmedResponse?.discounts &&
              Object.values(this.confirmedResponse?.discounts).reduce(
                (a, b) => {
                  return a + b.totalSkus;
                },
                0
              )}
              skus changed across
              ${this.confirmedResponse?.discounts &&
              Object.values(this.confirmedResponse?.discounts)?.length}
              stores
            </h3>
            ${this.confirmedResponse?.discounts &&
            Object.values(this.confirmedResponse?.discounts).map(
              (p) =>
                html`<ul>
                  <li>
                    ${p.totalSkus} skus changed for: ${p.storeName}
                    <ul>
                      ${Object.entries(p.storeDiscounts).map(
                        ([percent, total]) =>
                          html`<li>
                            <strong>${total}</strong> SKUs discounted to
                            <strong>${percent}</strong>
                          </li>`
                      )}
                    </ul>
                  </li>
                </ul>`
            )}
          </div>
          <div class="button-group">
            <button class="button" @click=${() => this.setActiveTabIndex(2)}>
              <span ?hidden=${this.state == status.Saving}
                >Return to Start</span
              >
            </button>
          </div>
        </div>
      </div>
      <section id="upload-history" ?hidden=${this.activeTabIndex !== 1}>
        <h2 class="page-heading">Discount Uploads History</h2>
        <p class="page-subtext">View recent discount upload history</p>
        <div class="page-body">
          <mwc-button class="view" raised @click=${this.getDiscountUploads}>
            View Uploads
          </mwc-button>
          <div class="uploads">
            ${this.discountUploads.map(
              (upload) =>
                html`<upload-item
                  .service=${this.service}
                  .upload=${upload}
                ></upload-item>`
            )}
          </div>
          <p class="error" ?hidden=${this.state !== status.Errored}>
            There has been an error getting the uploads.
            ${this.error?.error || this.error}
          </p>
          <p class="loading" ?hidden=${this.state !== status.Loading}>
            Loading uploads.
          </p>
        </div>
      </section>
    `;
  }

  /**
   * Defines the elements properties
   *
   * @return {object} the props
   */
  static get properties() {
    return {
      /** The data parsed from the route url */
      routeData: { type: Object },
      /** The service for getting data */
      service: { type: Object },
      /** What state we are in */
      state: { type: String },
      /** Any error from the API */
      error: { type: Object },
      /** The list of discount upload files */
      discountUploads: { type: Array },
      activeTabIndex: Number,
      validationResponse: { type: Object },
      confirmedResponse: { type: Object },
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.routeData = {};
    this.service = {};
    this.state = status.Idle;
    this.error = {};
    this.activeTabIndex = 1;
    this.validationResponse = {};
    this.confirmedResponse = {};
    this.discountUploads = [];
  }

  /**
   * Helper to get the form element
   * @return {HTMLElement} the form element
   */
  get form() {
    return this.shadowRoot.querySelector('form');
  }

  /**
   * Helper to get the form element
   * @return {HTMLElement} the form element
   */
  get fileInput() {
    return this.shadowRoot.querySelector('input[name="upload"]');
  }

  /**
   * updated
   * @param {*} changedProperties
   */
  updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has('error')) {
      if (this.error?.error || this.error) {
        this.form.reset();

        // using innerHTML remove elements from Lits control this is suggested approach
        const icon = this.shadowRoot.getElementById('upload-icon');
        // remove existing child nodes to avoid duplicates
        icon.childNodes.forEach((node) => node.remove());

        // append new value for icon
        icon.appendChild(document.createTextNode('upload'));

        const target = this.shadowRoot.getElementById('file-upload-visualiser');
        // remove existing child nodes to avoid duplicates
        target.childNodes.forEach((node) => node.remove());

        // reset file name
        target.appendChild(document.createTextNode(''));
      }
    }
  }

  /**
   * Sets the active tab index
   * @param {*} activeTab the tab index
   */
  setActiveTabIndex(activeTab) {
    this.activeTabIndex = activeTab;
  }

  /**
   * Wrapper to reset the form and state
   */
  resetForm() {
    this.form.reset();
    this.state = status.Idle;

    // using innerHTML remove elements from Lits control this is suggested approach
    const icon = this.shadowRoot.getElementById('upload-icon');
    // remove existing child nodes to avoid duplicates
    icon.childNodes.forEach((node) => node.remove());

    // append new value for icon
    icon.appendChild(document.createTextNode('upload'));

    const target = this.shadowRoot.getElementById('file-upload-visualiser');
    // remove existing child nodes to avoid duplicates
    target.childNodes.forEach((node) => node.remove());

    // reset file name
    target.appendChild(document.createTextNode(''));
  }

  /**
   * Reset Wizard
   */
  resetWizard() {
    this.resetForm();
    this.validationResponse = {};
  }

  /**
   * onChange
   * @param {*} e
   */
  onChange(e) {
    const target = this.shadowRoot.getElementById('file-upload-visualiser');
    const fileName = this.fileInput.files[0].name || '';
    const icon = this.shadowRoot.getElementById('upload-icon');

    // using innerHTML remove elements from Lits control this is suggested approach
    // remove existing child nodes to avoid duplicates
    icon.childNodes.forEach((node) => node.remove());
    target.childNodes.forEach((node) => node.remove());

    // change icon in UI
    // set file name for UI
    icon.appendChild(document.createTextNode('text_snippet'));
    target.appendChild(document.createTextNode(fileName));

    this.validationResponse = {};
    this.state = status.Idle;
  }

  /**
   * onDrop
   * @param {*} e
   */
  onDrop(e) {
    e.preventDefault(); // Prevent default browser behavior
    const files = e.dataTransfer.files;

    if (files.length > 0) {
      // Trigger the file input's change event programmatically
      this.fileInput.files = files;
      this.onChange(e);
    }
  }

  /**
   * onDropPass
   * @param {*} e
   * @return {boolean}
   */
  onDropPass(e) {
    e.preventDefault(); // Prevent default browser behavior

    this.onDrop(e);

    return false;
  }

  /**
   * onDragOverPass
   * @param {*} e
   * @return {boolean}
   */
  static onDragOverPass(e) {
    e.preventDefault(); // Prevent default browser behavior

    return false;
  }

  /**
   * Retrieves price discount uploads
   */
  async getDiscountUploads() {
    try {
      this.state = status.Loading;
      let uploads = await this.service.send('/uploads/discounts', 'GET');
      uploads = uploads.sort((a, b) => {
        return new Date(b.uploadDate) - new Date(a.uploadDate);
      });
      this.discountUploads = uploads;
      this.state = status.Loaded;
    } catch (e) {
      this.state = status.Errored;
      this.error = e;
      datadogRum.addError(e, { source: 'price-discount-page' });
    }
  }

  /**
   * Handles the validation of the form and validated the uploaded CSV
   *
   * @param {Event} event the submission event
   */
  async validateUpload(event) {
    event.preventDefault();
    try {
      const valid = this.form.reportValidity();
      if (!valid) {
        return;
      }

      this.state = status.Saving;
      const formdata = this.getFormDataWithConvertedTime();

      //   validate endpoint
      const validate = await this.service.send(
        '/uploads/discounts/validate',
        'POST',
        formdata
      );

      this.validationResponse = validate;

      setTimeout(() => {
        this.setActiveTabIndex(3);

        this.state = status.Idle;
      }, 2000);
    } catch (e) {
      this.state = status.Errored;
      let error = e;

      if (Array.isArray(e)) {
        error = e.map(
          ({ sku, error: message }) =>
            html`<p>Error with SKU ${sku}: ${message}</p>`
        );
      }

      this.error = error;
      datadogRum.addError(e, { source: 'price-discount-page' });
    }
  }

  /**
   * Handles the submission of the form, getting the data from the form and sending it to the service
   *
   * @param {Event} event the submission event
   */
  async confirmUpload(event) {
    event.preventDefault();
    try {
      const valid = this.validationResponse.validationId?.length;
      if (!valid) {
        return;
      }

      this.state = status.Saving;

      const confirmation = await this.service.send(
        `/uploads/discounts/process/${this.validationResponse.validationId}`,
        'POST'
      );

      //   If all is successful set validation response to confirmed response
      this.confirmedResponse = confirmation;

      setTimeout(() => {
        this.state = status.Idle;
        this.setActiveTabIndex(4);
        this.resetWizard();
      }, 2000);
    } catch (e) {
      this.state = status.Errored;
      let error = e;

      if (Array.isArray(e)) {
        error = e.map(
          ({ sku, error: message }) =>
            html`<p>Error with SKU ${sku}: ${message}</p>`
        );
      }

      this.error = error;
      datadogRum.addError(e, { source: 'price-discount-page' });
    }
  }

  /**
   * Converts the scheduled time to ISO format and get the formdata
   *
   * @return {FormData} the form data with the scheduled time converted to ISO format
   */
  getFormDataWithConvertedTime() {
    const formdata = new FormData(this.form);

    const scheduledTime = formdata.get('scheduledTime');
    if (scheduledTime) {
      formdata.set('scheduledTime', new Date(scheduledTime).toISOString());
    }

    formdata.set('discountTemplateType', 'fixed-price-discount');

    return formdata;
  }

  /**
   * Gets the minimum scheduled time, 30 minutes from now
   *
   * @return {string} the minimum scheduled time
   */
  static get minScheduledTime() {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    const time = now.toLocaleTimeString('en-GB').substring(0, 5);
    const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-');
    return `${date}T${time}`;
  }
}
window.customElements.define('price-discount-page', PriceDiscountPage);
