/* eslint-disable max-len */
/* eslint-disable lit/binding-positions */
/* eslint-disable object-curly-spacing */
/* eslint-disable indent */
/* eslint-disable comma-dangle */

import { LitElement, html, css } from 'lit';
import '@material/mwc-button';
import { status } from '../elements/statuses';
import { sharedStyles } from '../shared-styles';
import { datadogRum } from '@datadog/browser-rum';
import '../elements/upload-item';

/**
 * `price-discount-page`
 *  Display the uploader for product price discounts files
 */
export class RemovePriceDiscountPage extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    const style = css`
      small {
        display: block;
        margin: 10px;
      }

      small > a {
        display: block;
      }

      .file-upload-wrapper {
        position: relative;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23919191' stroke-width='6' stroke-dasharray='10%2c25' stroke-dashoffset='36' stroke-linecap='square'/%3e%3c/svg%3e");
        height: 300px;
        border-radius: 24px;
        border: none;
        margin-top: 16px;
        width: 100%;
        box-sizing: border-box;
        cursor: pointer;

        .material-icons {
          position: absolute;
          left: 50%;
          top: 50%;
          font-size: 70px;
          transform: translate(-50%, -50%);
        }

        &:hover {
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23000' stroke-width='6' stroke-dasharray='10%2c25' stroke-dashoffset='36' stroke-linecap='square'/%3e%3c/svg%3e");

          .material-icons {
            font-size: 74px;
          }
        }
      }

      .file-upload {
        opacity: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;

        &::file-selector-button {
          display: none;
        }

        &:valid {
          opacity: 1;
          border: none;
          padding: 100px 38%;
          box-sizing: border-box;
          color: transparent;
          background: #f7f7f7;
        }
      }

      .error {
        background: #eeeeee;
        padding: 1.6rem;
        border-radius: 0.8rem;
        font-family: monospace;
      }

      .filename {
        position: absolute;
        top: 65%;
        left: 0;
        right: 0;
        transform: translateY(-35%);
        text-align: center;
      }

      .loading {
        text-align: center;
        font-size: 2.4rem;
        font-weight: 700;
        font-family: 'montserrat';
        text-transform: uppercase;
      }
    `;
    return [sharedStyles, style];
  }

  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    return html`
      <h2 class="page-heading">Remove Price Discounts</h2>
      <div class="page-body">
        <form>
          <label>
            Upload a Price Discount CSV file
            <div class="file-upload-wrapper">
              <input
                class="file-upload"
                name="upload"
                type="file"
                required
                accept=".csv"
                @change=${this.onChange}
              />
              <span class="material-icons" id="upload-icon">upload</span>
              <span class="filename" id="file-upload-visualiser"></span>
            </div>
          </label>
          <small
            >The CSV file is expected in a specific format.
            <a
              href="/templates/international-price-discounts.csv"
              download="price-discounts.csv"
              >Download the International template here.</a
            >
            <a
              href="/templates/north-america-price-discounts.csv"
              download="price-discounts.csv"
              >Download the North America template here.</a
            >
            <ul>
              <li>
                You can include one or more store code columns, a column for
                each store you wish to discount
              </li>
              <li>
                If you do not wish to remove discounts from a store, then do not
                include the store column in the file
              </li>
              <li>Each row must have the Customer SKU and Vendor SKU</li>
              <li>
                Values must not include any currency or percentage symbols
              </li>
              <li>The value is the discounted price of product</li>
              <li>
                If you wish to remove a discount, provide the store column and
                leave the value blank
              </li>
              <li>
                Prices will have the discount removed in Shopify within minutes
                of upload or the scheduled time you have chosen
              </li>
              <li>
                <i
                  >Any blank values will trigger the removal of discount for
                  that Customer SKU</i
                >
              </li>
            </ul>
          </small>
          <mwc-button class="submit" raised @click=${this.handleUpload}>
            Upload
          </mwc-button>
          <mwc-button class="cancel" @click=${this.resetForm}>
            Clear
          </mwc-button>
          <p
            aria-live="assertive"
            class="error"
            ?hidden=${this.state !== status.Errored}
          >
            Oops there has been an error uploading. Please try again.
            ${this.error?.error || this.error}
          </p>
          <p class="saving" ?hidden=${this.state !== status.Saving}>
            Uploading file.
          </p>
        </form>
        <h2>Discount Uploads History</h2>
        <mwc-button class="view" raised @click=${this.getDiscountUploads}>
          View Uploads
        </mwc-button>
        <div class="uploads">
          ${this.discountUploads.map(
            (upload) =>
              html`<upload-item
                .service=${this.service}
                .upload=${upload}
              ></upload-item>`
          )}
        </div>
        <p class="error" ?hidden=${this.state !== status.Errored}>
          There has been an error getting the uploads.
          ${this.error?.error || this.error}
        </p>
        <p class="loading" ?hidden=${this.state !== status.Loading}>
          Loading uploads.
        </p>
      </div>
    `;
  }

  /**
   * Defines the elements properties
   *
   * @return {object} the props
   */
  static get properties() {
    return {
      /** The data parsed from the route url */
      routeData: { type: Object },
      /** The service for getting data */
      service: { type: Object },
      /** What state we are in */
      state: { type: String },
      /** Any error from the API */
      error: { type: Object },
      /** The list of discount upload files */
      discountUploads: { type: Array },
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.routeData = {};
    this.service = {};
    this.state = status.Idle;
    this.error = {};
    this.discountUploads = [];
  }

  /**
   * Helper to get the input element
   * @return {HTMLElement} the input element
   */
  get fileInput() {
    return this.shadowRoot.querySelector('input[name="upload"]');
  }

  /**
   * onChange
   * @param {*} e
   */
  onChange(e) {
    const target = this.shadowRoot.getElementById('file-upload-visualiser');
    const fileName = this.fileInput.files[0].name || '';
    const icon = this.shadowRoot.getElementById('upload-icon');

    // using innerHTML remove elements from Lits control this is suggested approach
    // remove existing child nodes to avoid duplicates
    icon.childNodes.forEach((node) => node.remove());
    target.childNodes.forEach((node) => node.remove());

    // change icon in UI
    // set file name for UI
    icon.appendChild(document.createTextNode('text_snippet'));
    target.appendChild(document.createTextNode(fileName));
  }

  /**
   * updated
   * @param {*} changedProperties
   */
  updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has('error')) {
      if (this.error?.error || this.error) {
        this.form.reset();

        // using innerHTML remove elements from Lits control this is suggested approach
        const icon = this.shadowRoot.getElementById('upload-icon');
        // remove existing child nodes to avoid duplicates
        icon.childNodes.forEach((node) => node.remove());

        // append new value for icon
        icon.appendChild(document.createTextNode('upload'));

        const target = this.shadowRoot.getElementById('file-upload-visualiser');
        // remove existing child nodes to avoid duplicates
        target.childNodes.forEach((node) => node.remove());

        // reset file name
        target.appendChild(document.createTextNode(''));
      }
    }
  }

  /**
   * Helper to get the form element
   * @return {HTMLElement} the form element
   */
  get form() {
    return this.shadowRoot.querySelector('form');
  }

  /**
   * Wrapper to reset the form and state
   */
  resetForm() {
    this.form.reset();
    this.state = status.Idle;

    // using innerHTML remove elements from Lits control this is suggested approach
    const icon = this.shadowRoot.getElementById('upload-icon');
    // remove existing child nodes to avoid duplicates
    icon.childNodes.forEach((node) => node.remove());

    // append new value for icon
    icon.appendChild(document.createTextNode('upload'));

    const target = this.shadowRoot.getElementById('file-upload-visualiser');
    // remove existing child nodes to avoid duplicates
    target.childNodes.forEach((node) => node.remove());

    // reset file name
    target.appendChild(document.createTextNode(''));
  }

  /**
   * Retrieves price discount uploads
   */
  async getDiscountUploads() {
    try {
      this.state = status.Loading;
      let uploads = await this.service.send('/uploads/discounts', 'GET');
      uploads = uploads.sort((a, b) => {
        return new Date(b.uploadDate) - new Date(a.uploadDate);
      });
      this.discountUploads = uploads;
      this.state = status.Loaded;
    } catch (e) {
      this.state = status.Errored;
      this.error = e;
      datadogRum.addError(e, { source: 'remove-price-discount-page' });
    }
  }

  /**
   * Handles the submission of the form, getting the data from the form and sending it to the service
   *
   * If a scheduled time is set, it is converted to ISO format before sending so we don't need to deal with timezones
   *
   * @param {Event} event the submission event
   */
  async handleUpload(event) {
    event.preventDefault();
    try {
      this.state = status.Saving;
      const valid = this.form.reportValidity();
      if (!valid) {
        return;
      }

      const formdata = new FormData(this.form);
      await this.service.send('/uploads/discounts/remove', 'POST', formdata);
      this.state = status.Idle;
    } catch (e) {
      this.state = status.Errored;
      this.error = e;
      datadogRum.addError(e, { source: 'remove-price-discount-page' });
    }
  }

  /**
   * Gets the minimum scheduled time, 30 minutes from now
   *
   * @return {string} the minimum scheduled time
   */
  static get minScheduledTime() {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    const time = now.toLocaleTimeString('en-GB').substring(0, 5);
    const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-');
    return `${date}T${time}`;
  }
}
window.customElements.define(
  'remove-price-discount-page',
  RemovePriceDiscountPage
);
